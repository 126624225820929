import { ADD_EV_CUSTOM_VARIABLE, APP_INTEGRATION_EMAIL_VERIFIER, EMAIL_VERIFIER_THOROUGH_VERIFICATION, SINGLE_EMAIL_VERIFY_GET_DATA, USER_INTEGRATION_AUTH_MAILCHIMP } from 'api/endpoints/emailVerifierEndpoints';
import { BaseApi } from 'api/services/base-api';
import { AxiosResponse } from 'axios';
import { buildUrl } from 'utility/utils';

export const AuthUserIntegrationMailchimpAPI = async (queryParams: Record<string, any>) => {
  try {
    const response: AxiosResponse<any, any> = await BaseApi.post(USER_INTEGRATION_AUTH_MAILCHIMP, queryParams);
    return response;
  } catch (error) {
    console.error('User Integration Auth error occured ', error);
    throw error;
  }
};

export const GetAppIntegrationDataAPI = async () => {
  try {
    const response: AxiosResponse<any, any> = await BaseApi.get(APP_INTEGRATION_EMAIL_VERIFIER);
    return response;
  } catch (error) {
    console.error('App Integration Data error occured ', error);
    throw error;
  }
};

export const PostEVThoroughVerificationAPI = async (queryParams: Record<string, any>) => {
  try {
    const response: AxiosResponse<any, any> = await BaseApi.post(EMAIL_VERIFIER_THOROUGH_VERIFICATION, queryParams);
    return response;
  } catch (error) {
    console.error('Thorough Verification error occured ', error);
    throw error;
  }
};

export const GetSingleEmailVerifyResultDataAPI = async (queryParams: Record<string, any>) => {
  try {
    const getUrl = buildUrl({ url: SINGLE_EMAIL_VERIFY_GET_DATA, query: queryParams });
    const response: AxiosResponse<any, any> = await BaseApi.get(getUrl);
    return response;
  } catch (error) {
    console.error('App Integration Data error occured ', error);
    throw error;
  }
};

export const AddEvCustomVariableAPI = async (data: Record<string, any>) => {
  try {
    // const getUrl = buildUrl({ url: SINGLE_EMAIL_VERIFY_GET_DATA, query: queryParams });
    const response: AxiosResponse<any, any> = await BaseApi.post(ADD_EV_CUSTOM_VARIABLE, data);
    return response;
  } catch (error) {
    console.error('App Integration Data error occured ', error);
    throw error;
  }
};
