import React from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import FroalaEditorCore from 'froala-editor';

// Css
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import '../../../styles/TextEditor.scss'
// Toolbar options
import 'froala-editor/js/plugins.pkgd.min.js';
import { useAppDispatch } from 'redux-store';
import { insertVariable } from 'redux-store/campaign-v2';
import registerMyDropdownCommand from './custom-buttons/customVariable';
import { customVariablePlugin } from './custom-plugins/custom-variable';
import { BaseApi } from 'api/services/base-api';

interface propsType {
  initialValue?: string;
  editorContent?: string;
  onChange?: (event: any) => void;
  onFocus?: (event: any, editor: any) => void;
  leftContent?: any;
  rightContent?: any;
  editorRef?: any;
  height?: string | number;
  list?: string[];
  id?: string;
  variablePattern?: boolean;
  variables?: any;
  missedVariables?: any[];
  variablesClass?: any;
  placeholder?: string;
  variableList?: any[];
  variable_area?: string;
  plugins?: string[];
  customButton?: string[];
}

const TextEditor = (props: propsType) => {
  const {
    variableList = [],
    variable_area = '',
    onFocus = (event: any) => {},
    height,
    editorContent = '',
    placeholder,
    initialValue,
    onChange = (data: string) => {},
    missedVariables = [],
    plugins = [],
    customButton = [],
  } = props;
  const [content, setContent] = React.useState(initialValue || '');
  const editorRef = React.useRef(null);
  const dispatch = useAppDispatch();

  // Plugins
  React.useEffect(() => {
    if (plugins?.includes('customVariablePlugin')) {
      customVariablePlugin(FroalaEditorCore, variableList, missedVariables);
    }
  }, [variableList, missedVariables, plugins]);

  // Variable dropdown
  React.useEffect(() => {
    if (variableList) {
      const updateSubject = (variable: string) => {
        dispatch(insertVariable(variable));
      };
      registerMyDropdownCommand(variableList, missedVariables, editorRef?.current?.editor, variable_area, updateSubject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variableList, variable_area, editorRef?.current]);

  // On editorContent change
  React.useEffect(() => {
    editorContent && setContent(editorContent);
  }, [editorContent]);

  // On content change
  React.useEffect(() => {
    // Replace styled variable to normal variable
    const replaceBackToVariables = (content: string) => {
      const regex = /<span[^>]*class=['"][^'"]*sparkle_variable[^'"]*['"][^>]*>(.*?)<\/span>/g;
      // const regex = /<span[^>]*class=['"][^'"]*sparkle_variable[^'"]*['"][^>]*>(.*?)<\/span>\s*/g;
      const updatedContent = content?.replace(regex, (match: string, capturedValue: string) => {
        const variable = variableList?.find((item) => item?.name === capturedValue)?.variable;
        return variable || `{{${capturedValue}}}`;
      });

      return updatedContent || '';
    };

    let formattedContents = content;
    if (plugins?.includes('customVariablePlugin')) {
      formattedContents = replaceBackToVariables(content);
    }

    onChange?.(formattedContents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  // Froala configuration
  const froalaConfig = {
    key: 'hWA2C-7J2A4A3B1B2C1C1wd1DBKSPF1WKTUCQOa1OURPJ1KDe2F-11D2C2D2D2C3A3B2D6E1C1==',
    placeholderText: placeholder || 'Enter message here...',
    charCounterCount: false,
    wordCounterCount: false,
    height: height || 250,
    zIndex: 2501,
    imageDefaultAlign: 'left',
    // toolbarBottom: true,
    events: {
      focus: function (e: any, editor: any) {
        onFocus?.(e, editor);
      },
      'image.beforeUpload': function (images: any) {
        const editor: any = this;
        editor.image.showProgressBar(); // Show the image loader
        const formData = new FormData();
        formData.append('documents', images[0]);
        BaseApi.post('/helper/store/az/bucket', formData)
          .then((response: any) => {
            editor?.image?.insert(response?.data?.url, null, null, editor.image.get());
          })
          .catch((err) => {
            console.log(err);
          });
        return false;
      },
    },
    pluginEnabled: plugins || [],
    quickInsertEnabled: false,
    enter: FroalaEditorCore.ENTER_DIV,
    useClasses: false, // Image style in inline
    toolbarButtons: {
      moreText: {
        buttons: [
          ...customButton,
          'bold',
          'underline',
          'italic',
          'strikeThrough',
          'subscript',
          'superscript',
          'fontFamily',
          'fontSize',
          'textColor',
          'backgroundColor',
          'clearFormatting',
        ],
        align: 'left',
        buttonsVisible: 3,
      },
      moreParagraph: {
        buttons: ['alignLeft', 'alignCenter', 'formatOL', 'formatUL', 'alignRight', 'alignJustify', 'formatOLSimple', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
        align: 'left',
        buttonsVisible: 3,
      },
      moreRich: {
        buttons: ['insertLink', 'insertImage', 'emoticons', 'html', 'insertVideo', 'insertTable', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
        align: 'left',
        buttonsVisible: 4,
      },
      moreMisc: {
        buttons: ['undo', 'redo', 'html', 'selectAll', 'help'],
        align: 'right',
        buttonsVisible: 2,
      },
    },
  };

  return (
    <div className="textEditor relative">
      <FroalaEditor
        ref={editorRef}
        tag="div"
        // key={'hWA2C-7J2A4A3B1B2C1C1wd1DBKSPF1WKTUCQOa1OURPJ1KDe2F-11D2C2D2D2C3A3B2D6E1C1=='}
        model={content}
        onModelChange={setContent}
        config={froalaConfig}
      />
    </div>
  );
};

export default TextEditor;
