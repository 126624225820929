import TextField from 'components/forms/TextField';
import { AZ_BUCKET } from 'config/appConfig';
import React, { useState } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { DeleteCardforUser, FetchCardDataforUser, FetchUserInvoiceData, InvoiceMemoUpdate } from 'shared/billing/event-handler/stripe-card-segment';
import AddNewCardModal from './AddNewCardModal';
// import { BaseApi } from 'api/services/base-api';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import DeleteConfirmModal from './DeleteModalConfirmation';
import { BaseApi } from 'api/services/base-api';
import { toast } from 'react-toastify';
import { toastState } from 'utility/utils';
import EditCardModal from './EditCardModal';
import { Box, Skeleton, SkeletonCircle, Stack } from '@chakra-ui/react';
const stripePromise = loadStripe('pk_test_51Pdd6HDleA4po2BhBdil0zj8BqyeRQCQNTG9NiqiAMnemaeZDqBS6CHMsJIDdmcIBLMPEcTvoq2o35lDYJ7JSr3i00OPpT1PGB');
// import BasicBilling from '../../../../../../assets/json/basic-new-billing-details.json';
// import ProBilling from '../../../../../../assets/json/pro-new-billing-details.json';

const StripeCardSection = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState([]);
  const [invoiceMemo, setInvoiceMemo] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState('');
  const [trigger, setTrigger] = React.useState(false);
  const [editCardData, setEDitCardData] = React.useState({});
  React.useEffect(() => {
    FetchCardDataforUser({ setLoading, setData });
    FetchUserInvoiceData({ setInvoiceMemo, setData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const handleInvoiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceMemo(e.target.value);
  };

  const handleSubmitInvoice = async () => {
    await InvoiceMemoUpdate(invoiceMemo);
  };

  const handleDefaultSetting = async (id: string) => {
    try {
      const response = await BaseApi.post(`/billing/usage/card/default`, { paymentMethodId: id });
      if (response.error) {
        toast.error(`Error attaching card`, toastState.error);
      } else {
        toast.success('Card set as default successfully!', toastState.success);
        setTrigger((prev) => !prev);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-[90rem] ">
      <hr />
      <div className="my-5 flex gap-x-3">
        <div className="w-64  pl-2 pt-1">
          <p className="text-[13px] font-semibold text-paused-color">Invoice memo</p>
          <p className="break-words text-[13px] text-column">Add all required details that will be listed on your invoice.</p>
        </div>
        <div>
          <TextField
            extra=""
            inputClass="!w-[25rem] dark:!bg-darkFive brand_geography_target_input resize-none !rounded-md"
            labelClass="!text-column dark:!text-white !font-[600] !ml-0 text-xs"
            placeholder="Memo"
            id="memo"
            cols={30}
            rows={6}
            onChange={handleInvoiceChange}
            value={invoiceMemo}
            onBlur={handleSubmitInvoice}
            //   errorMessage={errorMessage?.geography_target}
            //   state={errorMessage?.geography_target ? 'error' : ''}
          />
        </div>
      </div>
      <div className="mt-3 flex flex-col gap-4 sm:flex-row">
        <div className="w-full  p-3 sm:w-64">
          <p className="text-[13px] font-semibold text-paused-color">Card details</p>
          <p className="mt-1 text-[13px] text-column">Select default payment method</p>
        </div>

        <div className="flex-1 overflow-x-auto">
          {loading ? (
            <Stack spacing={5} direction="row" py={2}>
              {[...Array(3)].map((_, idx) => (
                <Box key={idx} width="16rem" border="1px solid #e2e8f0" borderRadius="lg" padding="4" boxShadow="sm" backgroundColor="white">
                  {/* Card Brand Icon Skeleton */}
                  <Box display="flex" alignItems="center" gap={3}>
                    <SkeletonCircle size="8" />
                    <Box flex="1">
                      <Skeleton height="8px" width="70%" mb={2} />
                      <Skeleton height="5px" width="50%" />
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <Skeleton height="10px" width="60%" />
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mt={6}>
                    <Skeleton height="15px" width="80px" borderRadius="md" />
                    <Box display="flex" gap={3}>
                      <SkeletonCircle size="6" />
                      <SkeletonCircle size="6" />
                    </Box>
                  </Box>
                </Box>
              ))}
            </Stack>
          ) : (
            <>
              <div className="flex gap-3 py-1.5">
                {data?.map((item: any, idx: number) => (
                  <div key={idx} className="min-w-[16rem] rounded-lg border border-graybg bg-white p-3 shadow-sm">
                    <div className="flex gap-3">
                      <div className="h-6 w-10">
                        <img src={item?.card?.brand === 'mastercard' ? AZ_BUCKET?.STRIPE_MASTER_CARD : AZ_BUCKET?.STRIPE_VISA_CARD} alt="card-brand" className="h-full w-full object-contain" />
                      </div>

                      <div className="flex-1">
                        <p className="flex items-center justify-between text-xs font-semibold text-heading">
                          <span className="truncate">xxxx xxxx xxxx {item?.card?.last4}</span>
                          {item?.default_card ? <IoMdCheckmarkCircleOutline size={16} className="text-green-500" /> : null}
                        </p>
                        <p className="mt-1 text-xs text-heading">
                          Expiry {item?.card?.exp_month}/{item?.card?.exp_year}
                        </p>
                        <div className="mt-2 flex items-center justify-between">
                          <span className={`rounded-xl px-2 py-1 text-[10px] ${item?.default_card ? 'bg-bglightSkyBlue text-textDarkBlue' : 'cursor-pointer border border-gray-300 text-textDarkBlue'}`} onClick={!item?.default_card ? () => handleDefaultSetting(item?.id) : undefined}>
                            {item?.default_card ? 'Default' : 'Set as Default'}
                          </span>

                          <div className="flex gap-2">
                            <AiOutlineEdit
                              className="cursor-pointer text-sm text-column"
                              onClick={() => {
                                setIsEditModalOpen(true);
                                setEDitCardData(item);
                              }}
                            />
                            <AiOutlineDelete
                              className="cursor-pointer text-sm text-column"
                              onClick={() => {
                                setDeleteId(item?.id);
                                setDeleteModal(true);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          <div className="mt-3 cursor-pointer whitespace-nowrap px-2 text-sm text-column" onClick={() => setIsOpen(true)}>
            + Add new payment method
          </div>
        </div>
      </div>
      <Elements stripe={stripePromise}>
        <AddNewCardModal
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
            setTrigger((prev) => !prev);
          }}
        />
      </Elements>
      <EditCardModal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          setEDitCardData({});
          setTrigger((prev) => !prev);
        }}
        cardData={editCardData}
      />
      <DeleteConfirmModal
        isOpen={deleteModal}
        onClose={() => {
          setDeleteModal(false);
          setDeleteId('');
        }}
        handleDelete={() => DeleteCardforUser(deleteId, setTrigger, setDeleteModal)}
      />
    </div>
  );
};

export default StripeCardSection;
